.marketingSpend {
    font-family: 'DM Sans', sans-serif;
    background-color: #f1f5f9;
    display: flex;
    height: 100vh;
    width: calc(100% - 280px);
    margin-left: 280px;
    margin-top: 80px;
    overflow-x: hidden;
    padding-right: 45px;
    box-sizing: border-box;
}

.mainContent {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.breadcrumbs {
    padding: 20px 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.pageName {
    font-size: 40px;
    line-height: 120%;
    font-weight: 500;
    color: #1c2434;
}

.filters {
    display: flex;
    gap: 20px;
    margin-bottom: 30px;
    padding: 0 45px;
}

.filterItem {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.filterItem label {
    font-size: 14px;
    color: #374151;
    margin-bottom: 5px;
}

.filterItem select, 
.filterItem input {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #d1d5db;
    background-color: white;
    font-size: 14px;
    color: #374151;
}

.chartContainer {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
}

.chartTitle {
    font-size: 24px;
    margin-bottom: 20px;
    color: #1c2434;
}

.summaryTable {
    background-color: #ffffff; /* White background */
    border: 1px solid #e0e0e0; /* Light gray border */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    padding: 20px; /* Padding inside the box */
    margin: 20px 0; /* Margin around the box */
}

.summaryTable h2 {
    margin-top: 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #e0e0e0;
}

.summaryTable table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.summaryTable table th,
.summaryTable table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
}

.summaryTable .pagination {
    margin-top: 20px;
    text-align: center;
}

.summaryTable .pagination button {
    margin: 0 5px;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    background-color: #4a90e2;
    color: #fff;
    cursor: pointer;
}

.summaryTable .pagination button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.summaryTable .pagination .active {
    background-color: #f5a623;
}

.recharts-legend-wrapper {
    padding-top: 10px !important;
    text-align: left !important;  
}

.recharts-default-legend {
    text-align: left !important; 
}

.recharts-legend-item {
    margin-right: 20px !important;
}

.recharts-legend-item-text {
    color: #374151 !important;
    font-size: 14px !important;
}
