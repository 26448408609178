/* Topbar settings */
.topbar {
  height: 80px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align items to the right */
  padding: 0 20px;
  box-shadow: 0 1px 0 #e2e8f0;
  position: fixed; /* Fix the topbar */
  left: 280px; /* Align with the sidebar */
  right: 0;
  top: 0;
  z-index: 1000; /* Ensure it stays on top */
}

.topbarRight {
  display: flex;
  align-items: center;
}

.logoContainer {
  display: flex;
  align-items: center;
  margin-left: 20px;
  position: relative;
}

.ellipse-div {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background-color: #1c2434;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.logo {
  height: 36px;
  width: auto; /* Maintain aspect ratio */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.iconsContainer {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.icon {
  height: 24px;
  width: 24px;
  margin-right: 10px;
}

.dataDianaParent {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 20px;
}

.dataDiana {
  font-weight: 500;
}

.analyst {
  font-size: 12px;
  color: #637381;
}

.dropdown {
  position: relative;
  display: inline-block;
  margin-left: 10px;
}

.dropbtn {
  background-color: #fff;
  color: #000;
  border: none;
  cursor: pointer;
  font-size: 18px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1001;
  right: 0; /* Ensure it drops down to the left */
  top: 46px; /* Ensure it drops below the ellipse-div */
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* Keep this for JavaScript toggling */
.dropdown-content.show {
  display: block;
}

/* Add this for the open state of the button */
.dropbtn.open {
  background-color: #3e8e41;
}