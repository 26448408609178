/* Main container */
.marketingSimulations {
    font-family: 'DM Sans', sans-serif;
    background-color: #f1f5f9;
    display: flex;
    height: 100vh;
    width: calc(100% - 280px);
    margin-left: 280px;
    margin-top: 80px;
    overflow-x: hidden;
    padding-right: 45px;
    box-sizing: border-box;
}

/* Main content area */
.mainContent {
    flex: 1;
    display: flex;
    flex-direction: column;
}

/* Breadcrumbs */
.breadcrumbs {
    padding: 20px 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

/* Page name */
.pageName {
    font-size: 40px;
    line-height: 120%;
    font-weight: 500;
    color: #1c2434;
}

/* Content area */
.contentArea {
    flex: 1;
    padding: 0 45px 20px 45px;
    box-sizing: border-box;
}

/* Subtitle */
.subtitle {
    color: #637381;
    margin-bottom: 20px;
    font-size: 14px;
}

/* Filter section */
.filterSection {
    display: flex;
    gap: 20px;
    margin-bottom: 30px;
}

.filterItem {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.filterItem label {
    font-size: 14px;
    color: #374151;
    margin-bottom: 5px;
}

.filterItem select {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #d1d5db;
    background-color: white;
    font-size: 14px;
    color: #374151;
}

/* Simulation card */
.simulationCard {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.simulationHeader {
    display: flex;
    align-items: center;
    padding: 15px 30px;
    border-bottom: 1px solid #E5E7EB;
}

.headerIcon {
    margin-right: 12px;
}

.headerTitle {
    flex: 1;
    color: #212B36;
    font-size: 22px;
    font-weight: 700;
    margin: 0;
}

.headerDots {
    display: flex;
    gap: 3px;
    margin-right: 12px;
}

.dot {
    width: 5px;
    height: 5px;
    background: #47547D;
    border-radius: 50%;
}

.headerArrow {
    display: flex;
    align-items: center;
}

.simulationContent {
    padding: 20px;
}

.dateRange {
    font-size: 14px;
    color: #374151;
    margin-bottom: 20px;
}

/* Table styles */
.simulationTable {
    width: 100%;
    border-collapse: collapse;
}

.simulationTable th,
.simulationTable td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #E5E7EB;
}

.simulationTable th {
    font-weight: 600;
    color: #374151;
    font-size: 14px;
}

.simulationTable td {
    font-size: 16px;
    color: #212B36;
}

.metricName {
    font-weight: 600;
}

.positive {
    color: #22c55e;
}

.negative {
    color: #ef4444;
}

/* Media queries */
@media (max-width: 1024px) {
    .marketingSimulations {
        width: 100%;
        margin-left: 0;
    }

    .filterSection {
        flex-direction: column;
    }
}

@media (max-width: 768px) {
    .contentArea {
        padding: 20px;
    }

    .pageName {
        font-size: 24px;
    }

    .headerTitle {
        font-size: 20px;
    }

    .simulationTable {
        font-size: 14px;
    }
}
