.admin-selection-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 100px); /* Adjust based on your top nav height */
    padding: 20px;
    box-sizing: border-box;
}

.admin-selection-content {
    text-align: center;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.customer-list {
    list-style: none;
    padding: 0;
}

.customer-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 10px 0;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    max-width: 300px;
}

.customer-button:hover {
    background-color: #0056b3;
}

h2 {
    margin-bottom: 20px;
}
