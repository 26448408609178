.youtubeSummary {
    font-family: 'Inter', sans-serif;
    background-color: #f1f5f9;
    display: flex;
    height: 100vh;
    width: calc(100% - 280px);
    margin-left: 280px;
    margin-top: 80px;
    overflow-x: hidden;
    padding-right: 45px;
    box-sizing: border-box;
}

.mainContent {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.breadcrumbs {
    padding: 20px 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.pageName {
    font-size: 40px;
    line-height: 120%;
    font-weight: 500;
    color: #1c2434;
}

.summaryHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.accountName {
    font-size: 16px;
    font-weight: 500;
}

.campaignDropdown {
    position: relative;
    width: 200px;
}

.customSelect {
    background-color: white;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    padding: 8px 12px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dropdownMenu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #e2e8f0;
    border-top: none;
    border-radius: 0 0 4px 4px;
    z-index: 10;
}

.dropdownItem {
    padding: 8px 12px;
    cursor: pointer;
}

.dropdownItem:hover {
    background-color: #f1f5f9;
}

.activitySections {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.activitySection {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.activityHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.activityData {
    display: flex;
    justify-content: space-between;
}

.activityItem {
    flex: 1;
    text-align: center;
}

.activityLabel {
    font-size: 12px;
    color: #64748b;
    margin-bottom: 4px;
}

.activityValue {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 4px;
}

.weeklyTrends {
    font-size: 18px;
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 10px;
}

.conversionTable,
.deliveryTable {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
}

.tableHeader {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    text-align: left;
    padding: 12px;
    border-bottom: 1px solid #e2e8f0;
}

tfoot td {
    font-weight: 600;
}

th {
    font-weight: 600;
    color: #64748b;
}

td {
    font-size: 14px;
}

@media (max-width: 768px) {
    .summaryHeader {
        flex-direction: column;
        align-items: flex-start;
    }

    .campaignDropdown {
        width: 100%;
        margin-top: 10px;
    }

    .activityData {
        flex-direction: column;
    }

    .activityItem {
        width: 100%;
    }
}
