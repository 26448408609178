.admin-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.admin-content {
    display: flex;
    flex-grow: 1;
}

.admin-main-content {
    flex-grow: 1;
    padding: 20px;
    margin-left: 280px; /* Matches sidebar width */
    margin-top: 80px;   /* Matches top bar height */
    background-color: #f4f6f9;
}

.customer-menus-form-container {
    width: 100%;
    max-width: 600px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.1);
}

.customer-menus-form input, 
.customer-menus-form select {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.customer-menus-form label {
    display: block;
    margin-bottom: 10px;
}

.customer-menus-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.customer-menus-table th,
.customer-menus-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.customer-menus-table th {
    background-color: #f2f2f2;
}

.customer-menus-table td button {
    margin-right: 5px;
    padding: 6px 12px;
    background-color: #5465ff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.customer-menus-table td button:hover {
    background-color: #3d4db7;
}

.alert {
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 4px;
    font-weight: bold;
    text-align: center;
}

.alert.success {
    background-color: #d4edda;
    color: #155724;
}

.alert.error {
    background-color: #f8d7da;
    color: #721c24;
}
