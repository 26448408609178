.iframePage {
    font-family: 'Inter', sans-serif;
    background-color: #f1f5f9;
    display: flex;
    height: 100vh;
    width: calc(100% - 280px);
    margin-left: 280px;
    margin-top: 80px;
    overflow-x: hidden;
    padding-right: 45px;
    box-sizing: border-box;
}

.iframePage .mainContent {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.iframePage .contentArea {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.iframePage .iframeContent {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
