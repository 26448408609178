@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Electrolize:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');

body {
    margin: 0;
    line-height: normal;
    background-color: #f1f5f9; /* This is the default background color */
    font-family: 'DM Sans', sans-serif;
}

body.login-page {
    background-color: #191927;
}

html, body, #root, .app-container {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  padding: 20px;
  width: 100%;
}
