.marketingAttributionSummary {
  display: flex;
  height: 100vh;
  width: calc(100% - 280px); /* Subtract sidebar width */
  background-color: #f1f5f9;
  font-family: 'DM Sans', sans-serif;
  overflow-x: hidden; /* Prevent horizontal scroll */
  margin-left: 280px; /* Add margin to the left to avoid overlapping */
  margin-top: 80px; /* Add margin to the top to avoid overlapping with the topbar */
  padding-right: 45px; /* Adjust padding to ensure the content is within the viewport */
  box-sizing: border-box; /* Ensure padding is included in the element's total width */
}

/* Main content settings */
.mainContent {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Breadcrumb settings */
.breadcrumbs {
  padding: 20px 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pageName {
  font-size: 40px;
  line-height: 120%;
  font-weight: 500;
  color: #1c2434;
  font-family: Inter;
}

.breadcrumbsChild {
  width: 191px;
  height: 24px;
}

/* Content area settings */
.contentArea {
  flex: 1;
  padding: 0 45px;
  position: relative;
}

.driversOfGross {
  font-size: 20px;
  line-height: 120%;
  font-family: Inter;
  margin-bottom: 20px;
}

.modelRunDateContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.modelRunDate {
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #637381;
}

.dropdownWrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 250px;
}

.customSelect {
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #dce3eb;
  padding: 10px 16px;
  font-size: 14px;
  color: #212b36;
  width: 100%;
  max-width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdownIcon {
  width: 16px;
  height: 16px;
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #dce3eb;
  border-top: none;
  border-radius: 0 0 8px 8px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
  width: 100%; /* Ensures dropdown menu matches the width of the select box */
}

.dropdownItem {
  padding: 10px 16px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdownItem:hover {
  background-color: #f1f5f9;
}

/* Model Fit box settings */
.marketingAttributionSummaryInner {
  position: absolute;
  top: 0;
  right: 45px; /* Adjust to ensure it fits */
  width: auto;
  max-width: 287px;
}

.rectangleParent {
  width: 287px;
  height: 161px;
  position: relative;
}

.groupChild {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0px 8px 13px -3px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #dce3eb;
}

.flagAltParent {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  border-bottom: 1px solid #dce3eb; /* Add the horizontal line */
}

.flagIconContainer {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modelFit {
  font-size: 16px;
  line-height: 28px;
  flex-grow: 1;
  text-align: center;
}

.lineParent {
  position: absolute;
  top: 54px;
  left: 0;
  width: 100%;
  height: 107px;
}

.groupItem {
  position: absolute;
  top: 10px; /* Adjust top position */
  left: 50%;
  width: 1px;
  height: calc(100% - 20px); /* Adjust height */
  background-color: #dce3eb;
}

.rsqParent, .nrmseParent {
  position: absolute;
  top: 15px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.rsqParent {
  left: 0;
}

.nrmseParent {
  right: 0;
}

.nrmse {
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
}

.b {
  font-size: 28px;
  line-height: 34px;
  color: #212b36;
}

.flagAltIcon {
  width: 16px;
  height: 16px;
}

.graphContainer {
  width: calc(100% - 60px); /* Adjust width to align with Model Fit box */
  height: 400px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 8px 13px -3px rgba(0, 0, 0, 0.07);
  padding: 20px;
  margin-top: 80px; /* Increased margin to ensure the graph doesn't overlap */
  margin-left: 15px; /* Add margin to the left for alignment */
}

.additionalSpendContainer {
  width: calc(100% - 60px); /* Adjust width to align with Model Fit box */
  height: 500px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 8px 13px -3px rgba(0, 0, 0, 0.07);
  padding: 20px;
  margin-top: 40px; /* Increased margin to ensure the graph doesn't overlap */
  margin-left: 15px; /* Add margin to the left for alignment */
}

.additionalSpendContainer h3 {
  margin-bottom: 10px;
  font-size: 20px;
}

.additionalSpendContainer p {
  margin-bottom: 20px;
  font-size: 16px;
  color: #637381;
}

.saturationCurveContainer {
  width: calc(100% - 60px); /* Adjust width to align with Model Fit box */
  height: 500px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 8px 13px -3px rgba(0, 0, 0, 0.07);
  padding: 20px;
  margin-top: 40px; /* Increased margin to ensure the graph doesn't overlap */
  margin-left: 15px; /* Add margin to the left for alignment */
}

.saturationCurveContainer h3 {
  margin-bottom: 10px;
  font-size: 20px;
}

.saturationCurveContainer p {
  margin-bottom: 20px;
  font-size: 16px;
  color: #637381;
}

.custom-legend {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-legend .arrow {
  cursor: pointer;
  padding: 0 10px;
  font-size: 18px;
}

.custom-legend span {
  display: inline-block;
  max-width: 100px; /* Adjust this value to ensure proper width */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
