/* Sidebar.css */
.sidebar {
  width: 280px;
  height: 100%;
  background-color: #22283b;
  color: #8a99af;
  position: fixed; /* Ensure it stays fixed to the left */
  left: 0;
  top: 0;
  bottom: 0;
}

.sidebar-header {
  display: flex;
  align-items: center;
  padding: 20px 30px;
  background-color: #22283b;
}

.logo {
  height: 24px;
  width: auto;
  margin-right: 10px;
}

.logo-text {
  font-size: 18px;
  letter-spacing: -0.75px;
  line-height: 24px;
  font-family: Electrolize, sans-serif;
  color: #fff;
}

.menuContainer, .menuContainer1 {
  position: relative;
  left: 0px;
  width: 267px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.menuContainer {
  margin-top: 20px;
}

.menuContainer1 {
  margin-top: 20px;
}

.leftMenuIconParent, .layersParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 4px 4px 30px;
  gap: 10px;
  font-size: 13px;
  color: #5465ff;
}

.leftMenuIcons {
  width: 16px;
  height: 15.8px;
  overflow: hidden;
  flex-shrink: 0;
}

.tools, .channels {
  line-height: 20px;
  text-transform: uppercase;
}

.sidebarButton, .sidebarButton1 {
  width: 267px;
  border-radius: 0px 40px 40px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 15px 8px 30px;
  box-sizing: border-box;
  cursor: pointer; /* Add this line to show the hand cursor */
}

.sidebarButton.active, .sidebarButton1.active {
  background-color: #303955;
  color: #5465ff;
}

.sidebarNav {
  line-height: 24px;
  font-weight: 500;
  cursor: pointer; /* Add this line to show the hand cursor */
}
