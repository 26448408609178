.sidebar {
  width: 280px;
  height: 100%;
  background-color: #22283b;
  color: #8a99af;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
}

.sidebar-header {
  display: flex;
  align-items: center;
  padding: 20px 30px;
  background-color: #22283b;
}

.logo-text {
  font-size: 18px;
  color: white;
}

.menuContainer {
  margin-top: 20px;
}

.sidebarButton {
  width: 267px;
  border-radius: 0px 40px 40px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 15px 8px 30px;
  box-sizing: border-box;
  cursor: pointer;
  color: #8a99af;
  text-decoration: none;
}

.sidebarButton.active {
  background-color: #303955;
  color: #5465ff;
}

.sidebarButton:hover {
  background-color: #2d2f40;
}
