/* src/pages/Index.css */
body.login-page {
    background-color: #121212 !important; /* Dark background for the entire page */
}

.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.login-container {
    width: 100%;
    max-width: 800px;
    padding: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: rgba(30, 30, 47, 0.9); /* Slightly transparent dark background */
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    z-index: 2;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5); /* Add shadow for elevation effect */
}

.logo-container {
    position: absolute;
    top: -10px;
    left: 20px;
    display: flex;
    align-items: center;
    z-index: 3;
}

.octopus-logo {
    width: 60px;
    height: auto;
}

.tap-in-digital {
    font-size: 20px;
    font-weight: bold;
    margin-left: 10px;
    color: #ffffff;
    display: flex;
    align-items: center;
}

.left-panel {
    background-color: rgba(25, 25, 40, 0.8); /* Slightly darker than the right panel */
    padding: 60px 40px 40px;
    position: relative;
    z-index: 1;
}

.welcome-back {
    font-size: 16px;
    color: #ffffff;
    margin-top: 20px;
    margin-bottom: 20px;
}

.files-image {
    width: 100%;
    max-width: 250px;
    display: block;
    margin-top: 20px;
}

.right-panel {
    padding: 60px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(30, 30, 47, 0.8); /* Slightly transparent */
}

.account-login {
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 10px;
}

.sign-in-text {
    font-size: 14px;
    color: #ffffff;
    margin-bottom: 20px;
}

.google-signin-button {
    background-color: #4285f4;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
}
