.admin-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.admin-content {
    display: flex;
    flex-grow: 1;
}

.admin-main-content {
    flex-grow: 1;
    padding: 20px;
    background-color: #f4f6f9;
}

@media screen and (max-width: 768px) {
    .admin-main-content {
        margin-left: 0;
        margin-top: 70px;
    }
}
