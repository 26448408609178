/* SidebarLogo.css */
.sidebar-logo {
    display: flex;
    align-items: center;
    padding: 20px 30px;
    background-color: #22283b;
}

.full-logo-image {
    max-height: 50px; /* Increased from 32px or similar */
    width: auto;
}

/* If needed, adjust padding so it’s balanced around the larger logo */
.sidebar-logo {
    padding: 15px 30px; 
}
