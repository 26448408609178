.admin-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.admin-content {
    display: flex;
    flex-grow: 1;
}

.admin-main-content {
    flex-grow: 1;
    padding: 20px;
    margin-left: 280px; /* This matches the sidebar width */
    margin-top: 80px; /* Matches the height of the top bar */
    background-color: #f4f6f9;
}

.customer-enrollment-form-container {
    width: 100%;
    max-width: 600px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px; /* Added margin-bottom to separate form and table */
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.form-group {
    margin-bottom: 15px;
}

.customer-form input {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.customer-form label {
    margin-right: 10px;
}

.customer-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.customer-table th,
.customer-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.customer-table th {
    background-color: #f2f2f2;
}

.customer-table td button {
    margin-right: 5px;
    padding: 6px 12px;
    background-color: #5465ff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.customer-table td button:hover {
    background-color: #3d4db7;
}
