html, body, #root, .app-container {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'DM Sans', sans-serif;
}

.app-container.login-page {
  background-color: #000000; /* Black background for login page */
}

.app-container.overview-page {
  background-color: #f1f5f9; /* Light background for overview page */
}

.main-content {
  flex: 1;
  padding: 20px;
  width: 100%;
}
